import React, { useEffect, useState } from 'react';
import palette from 'theme/palette';
import { useIntl } from 'react-intl';
import { makeStyles, CircularProgress } from '@material-ui/core';
import styles from './styles';
import { useSelector } from 'react-redux';
import moment from 'moment';
import classNames from 'classnames';
import { Bar } from 'react-chartjs-2';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

const useStyles = makeStyles(styles);

export default function Accumulated(props) {
  const intl = useIntl();
  const classes = useStyles();
  const { className, ...rest } = props;
  const [days, setDays] = useState([]);
  const [filledData, setFilledData] = useState([]);
  const [totalPromData, setTotalPromData] = useState([]);
  const [totalPromLastData, setTotalPromLastData] = useState([]);

  const totalProm = useSelector(
    state => state.chargeDashboard.generalReport.total_prom_acomulate
  );
  const totalPromLast = useSelector(
    state => state.chargeDashboard.generalReport.total_prom_acomulate_last
  );
  const totalPromTotals = useSelector(
    state => state.chargeDashboard.generalReport.total_acomulate_totals
  );

  const localeNav = useSelector(state => state.prefs.locale);

  useEffect(() => {
    const daysInMonth = moment().daysInMonth();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const daysArray = [];
    const newFilledData = Array(daysInMonth).fill(0);
    const newTotalPromData = Array(daysInMonth).fill(0);
    const newTotalPromLastData = Array(daysInMonth).fill(0);

    // Inicializa todos los días del mes con 0
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push({
        day: i,
        weekday: moment({
          year: currentYear,
          month: currentMonth,
          day: i
        }).format('ddd'),
        value: 0 // Inicializa en 0
      });
      filledData.push(0); // Inicializa todos los valores del array con 0
    }

    // Rellena los días con la información de totalPromTotals
    if (totalPromTotals) {
      Object.keys(totalPromTotals).forEach(key => {
        const dayIndex = parseInt(key, 10) - 1;
        if (dayIndex < daysInMonth) {
          newFilledData[dayIndex] = totalPromTotals[key];
        }
      });
    }

    // Rellena los días con la información de totalProm
    if (totalProm) {
      Object.keys(totalProm).forEach(key => {
        const dayIndex = parseInt(key, 10) - 1;
        if (dayIndex < daysInMonth) {
          newTotalPromData[dayIndex] = totalProm[key];
        }
      });
    }

    // Rellena los días con la información de totalPromLast
    if (totalPromLast) {
      Object.keys(totalPromLast).forEach(key => {
        const dayIndex = parseInt(key, 10) - 1;
        if (dayIndex < daysInMonth) {
          newTotalPromLastData[dayIndex] = totalPromLast[key];
        }
      });
    }

    setFilledData(newFilledData);
    setTotalPromData(newTotalPromData);
    setTotalPromLastData(newTotalPromLastData);
    setDays(daysArray);
  }, [localeNav, totalPromTotals, totalProm, totalPromLast]);

  const labels = days.map(
    day => `${day.weekday.charAt(0).toUpperCase() + day.weekday.slice(1, 3)} ${day.day}`
  );

  const data = {
    labels: labels, // Etiquetas de los días del mes
    datasets: [
      {
        label: `${intl.formatMessage({id: 'app.charge.board.dailyAcumConsump.dataSet.tooltip.title'})} kWh`,
        backgroundColor: '#00AFEF',
        data: filledData, // Datos de la barra
        type: 'bar',
      },
      {
        label: `${intl.formatMessage({id: 'app.charge.board.dailyAcumConsump.dataSet.tooltip.currentMonth'})} kWh`,
        borderColor: '#A53692',
        borderWidth: 3,
        fill: false,
        data: totalPromData, // Repite el valor para cada día si solo tienes un valor
        type: 'line',
      },
      {
        label: `${intl.formatMessage({id: 'app.charge.board.dailyAcumConsump.dataSet.tooltip.lastMonth'})} kWh`,
        borderColor: '#ED685E',
        fill: false,
        borderDash: [10, 5],
        borderWidth: 1,
        data: totalPromLastData, // Igual para Mes Anterior
        type: 'line',
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      onClick: function(e, legendItem) {
        const index = legendItem.datasetIndex;
        const ci = this.chart;
        const meta = ci.getDatasetMeta(index);

        // Toggle the visibility
        meta.hidden =
          meta.hidden === null ? !ci.data.datasets[index].hidden : null;

        // Re-render the chart
        ci.update();
      }
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary,
      callbacks: {
        label: function(tooltipItem, data) {
          const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
          const value = parseFloat(tooltipItem.yLabel).toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
          return `${datasetLabel}: ${value}`;
        }
      }
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          position: 'right',
          ticks: {
            callback: function(value) {
              return value.toLocaleString('en', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              });
            },
            beginAtZero: true,
            autoSkip: true,
          }
        }
      ]
    },
    animation: {
      onComplete: function() {
        var chartInstance = this.chart;
        var ctx = chartInstance.ctx;
        ctx.textAlign = 'center';

        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.textBaseline = 'bottom';

        this.data.datasets[0].data.forEach(function(data, index) {
          const posX = chartInstance.getDatasetMeta(0).data[index]._model.x;
          const posY = chartInstance.getDatasetMeta(0).data[index]._model.y;

          ctx.fillText(data.toFixed(2), posX, posY - 5);
        }, this);
      }
    }
  };

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel
          title={intl.formatMessage({
            id: 'app.charge.board.dailyConsump.title'
          })}
        />
      </PortletHeader>
      <PortletContent>
        <div className={classes.chartWrapper}>
          {totalProm ? (
            <Bar
              data={data}
              options={options}
            />
          ) : (
            <CircularProgress />
          )}
        </div>
      </PortletContent>
    </Portlet>
  );
}
