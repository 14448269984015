/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

import { setMeterId } from 'js/actions/userActions.js';

import { makeStyles, Typography, Grid, Button} from '@material-ui/core';

import { Paper } from 'components';

import MaterialTable from 'material-table';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import ReactSpeedometer from 'react-d3-speedometer';
const useStyles = makeStyles(styles);

export default function MeterCar(props) {
  const intl = useIntl()
  const dispatch = useDispatch();
  const classes = useStyles();
  const { className, meter, setShowDetails, setSelectedCorp } = props;
  const rootClassName = classNames(classes.root, className);
  const { consumes } = meter;
  const consumesTable = [consumes[0], consumes[1]];

  let minVoltage=0;
  let maxVoltage=500;

  if( meter.rated_voltage>0 ){
    minVoltage= Math.floor( meter.rated_voltage*0.85);
    maxVoltage= Math.floor( meter.rated_voltage*1.15);
  }

 
  let voltPart0= Math.floor (meter.rated_voltage*0.85);
  let voltPart1= Math.floor (meter.rated_voltage*0.9  ) ;
  let voltPart2= Math.floor (meter.rated_voltage*0.95  ); 
  let voltPart3= Math.floor (meter.rated_voltage*1.05  ); 
  let voltPart4= Math.floor (meter.rated_voltage*1.1  );
  let voltPart5= Math.floor (meter.rated_voltage*1.15  );


  let voltValue= meter.voltage;
  if( meter.voltage<minVoltage){
    voltValue=minVoltage;
  }else if(meter.voltage>maxVoltage ){
    voltValue=maxVoltage;
  }

  let fpValue= meter.fp;
  if( meter.fp<70){
    fpValue=70;
  }else if(meter.fp>100 ){
    fpValue=100;
  }


 
  // console.log('minVoltage :' +  minVoltage );
  // console.log('maxVoltage:'+ maxVoltage );
  // console.log('volpart0 :' +  voltPart0 );
  // console.log('volpart1 :' +  voltPart1 );
  // console.log('volpart2 :' +  voltPart2 );
  // console.log('volpart3 :' +  voltPart3 );
  // console.log('volpart4 :' +  voltPart4 );
  // console.log('volpart5 :' +  voltPart5 );

  // console.log('meter.voltage :' + meter.voltage );

  const setAdminMeterId = meterId => {
    dispatch(setMeterId(meterId));
  };

  const columns = [
    {
      title: '' ,
      field: 'med',
      cellStyle: {
        fontWeight: '900',
        fontSize: '1em',
        textAlign: 'center',
        width: '10%',
        MinWidth: '10%'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.card.gridCard.baseCol'}),
      field: 'bas',

      cellStyle: {
        backgroundColor: '#8CBA42',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.card.gridCard.intermidateCol'}),
      field: 'int',
      cellStyle: {
        backgroundColor: '#EAAB36',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    },
    {
      title: intl.formatMessage({id: 'app.metersCards.card.gridCard.tipCol'}),
      field: 'punt',
      cellStyle: {
        backgroundColor: '#D64539',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        width: '30%',
        fontSize: '.95em'
      }
    }
  ];

  const data = [];

  let obj;

  consumesTable.forEach(element => {
    obj = {
      med: element[0],
      bas: element[1],
      int: element[2],
      punt: element[3]
    };

    data.push(obj);
  });


  const handleShowDetails = () => {
    setSelectedCorp(meter);
    setShowDetails(true);
  };

  return (
    <Paper className={rootClassName}>
      <div className={classes.details}>
        <Grid
          container
          spacing={1}
        >
          <Grid
            alignItems="flex-start"
            container
            item
            justifyContent="space-between"
            spacing={1}
            style={{margin: '2px 2px 1rem', justifyContent: 'space-between'}}
            xs={12}
          >
            <Grid
              className={classes.buttonMore}
              item
              lg={12}
              xs={12}
            >
              <Button
                color="secondary"
                onClick={handleShowDetails}
                variant="contained"
              >
                {intl.formatMessage({id: 'app.details.btn'})}  
              </Button>
            </Grid>
            <Grid
              container
              direction="column"
              item
              justifyContent="center"
              lg={7}
              style={{ padding: '2px' }}
              xs={7}
            >
              <Typography variant="h5">
                {meter.name}             
              </Typography>
              <Typography
                className={classes.heightLimit}
                variant="h5"
              >             
                {meter.description}
              </Typography>
              <Typography
                style={{backgroundColor: 'white'}}
                variant="body1"
              >             
                {intl.formatMessage({id: 'app.metersCards.card.city'})}: {meter.city}<br />
                {intl.formatMessage({id: 'app.metersCards.card.state'})}: {meter.state}
              </Typography>
                     
            </Grid>
            <Grid
              direction="column"
              item
              justifyContent="right"
              lg={4}
              style={{ textAlign: 'right' }}
              xs={4} 
            >
              <div  style={{   textAlign: 'right' , padding: '0px' , margin:'0px' }}      >
                <img
                  src="/images/icono3_co2.png"
                  style={{ width: '50px' , height: '50px' }}
                />
                <Typography
                  style={{ color:'#FF8024' , fontWeight :'bold' }}
                  variant="h5"
                >
                  {meter.consumes.co2} kg * 
                </Typography>                    
              </div>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <MaterialTable
              className={classes.table}
              columns={columns}
              data={data}
              options={{
                filtering: false,
                search: false,
                sorting: false,
                toolbar: false,
                paging: false,
                padding: 0,
                rowStyle: {
                  fontSize: 15,
                },
                headerStyle: {
                  textAlign: 'center',
                  padding: '0',
                  fontSize: '0.9em',
                  fontWeight: '900',
                  minWidth: '25%'
                }
              }}
              sx={{borderRadius: 1}}
            />
          </Grid>

          <Grid
            item
            lg={12}
            style={{display:'flex', gap: '2rem', justifyContent: 'flex-end', marginRight: '1rem'}}
            xl={12}
            xs={12}
          >
            <Typography
              className={classes.description}             
              style={{ marginTop: '5px', color: '#000' }}
              variant="h5"
            >
              {intl.formatMessage({id: 'app.metersCards.card.estAmtPerDay'})}
            </Typography>
          
            <Typography
              className={classes.description}
              style={{ color: '#218A1D', fontWeight: '600', marginTop: '5px' }}
              variant="h5"
            >
              $ {meter.consumes.total}
            </Typography>
          </Grid>  

          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            style={{ textAlign:'center' }}
            xl={6}
            xs={12}
          >
      
            <div  style={{     fontFamily:  'Lato, Helvetica Neue,Arial'  , textAlign:'center'  }}   >
              <ReactSpeedometer
                currentValueText={'' +  meter.fp}
                customSegmentStops={[70,80,90,100 ]}
                fontFamily={'Lato, Helvetica Neue,Arial'}
                forceRender
                height={120}
                labelFontSize={'12px'}
                maxValue={100}
                minValue={70}
                needleColor={'#000'}
                needleHeightRatio={0.7}
                paddingHorizontal={17}
                paddingVertical={17}
                ringWidth={30}
                segmentColors={['#EB0000', '#FAC800', '#00C454' ]}
                textColor={'#000'}
                value={fpValue}
                valueTextFontSize={'20px'}
                valueTextFontWeight={'600'} 
                width={135}
 
              />
            </div>

            <Typography
              className={classes.description}
              style={{  margin :'0px' ,  padding: '0px'  ,  color: '#000' , fontWeight :'600' }}
              variant="h5"
            >
              {intl.formatMessage({id: 'app.metersCards.card.fpLabel'})}
            </Typography>


          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            style={{ textAlign:'center' }}
            xl={6}
            xs={12}
          >
       

            <div  style={{     fontFamily:  'Lato, Helvetica Neue,Arial'  , textAlign:'center'  }}   >
              <ReactSpeedometer
                currentValueText={'' +  meter.voltage}
                customSegmentStops={[voltPart0, voltPart1, voltPart2 , voltPart3 , voltPart4 , voltPart5 ]}
                fontFamily={'Arial'}
                forceRender
                height={120}
                labelFontSize={'12px'}
                maxValue={maxVoltage}
                minValue={minVoltage}
                needleColor={'#000'}
                needleHeightRatio={0.7}
                paddingHorizontal={17}
                paddingVertical={17}
                ringWidth={30}
                segmentColors={['#EB0000', '#FAC800', '#00C454','#FAC800' , '#EB0000' ]}
                textColor={'#000'}
                value={voltValue}
                valueTextFontSize={'20px'}
                valueTextFontWeight={'600'}
                width={135}
 
              />
            </div>
            <Typography
              className={classes.description}
              style={{  margin :'0px' ,  padding: '0px'  ,   color: '#000' , fontWeight :'600' }}
              variant="h5"
            >               
              {intl.formatMessage({id: 'app.metersCards.card.voltageLabel'})}
            </Typography>


          </Grid>
  
          <Grid
            item
            xs={6}
          >
            <Link
              onClick={() => setAdminMeterId(meter.id)}
              to={{
                pathname: `/meter/${meter.id}`  ,
                state: {
                  meter
                }
              }}
            >
              <Button
                color="secondary"
                style={{ width: '100%' }}
                variant="contained"
              >
                {intl.formatMessage({id: 'app.groups.nav.groupLab2'})}
              </Button>
            </Link>
          </Grid>
          <Grid
            item
            xs={6}
          >
            <Link
              to={{
                pathname: `/meterDashboard/${meter.corporative_id}/${meter.id}`,                 
                state:{meter}
              }}
            >
              <Button
                color="secondary"
                style={{ width: '100%' }}
                variant="contained"
              >
                {intl.formatMessage({id: 'app.metersCards.card.boardBtn'})}
              </Button>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <Typography
              style={{ fontSize: '.9em', textAlign: 'center' }}
              variant="body1"
            >
              {intl.formatMessage({id: 'app.metersCards.card.meassCurrMonth'})}
            </Typography>
            <Typography
              style={{ fontSize: '.9em', textAlign: 'center'    }}
              variant="body1"
            > 
              {intl.formatMessage({id: 'app.metersCards.card.co2Msg'})}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
