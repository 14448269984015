import React, { useEffect, useState } from 'react';
import palette from 'theme/palette';
import { useSelector } from 'react-redux';
import regression from 'regression';

// Externals
import classNames from 'classnames';
import { Bar } from 'react-chartjs-2';

// Shared components
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent
} from 'components';

// Component styles
import styles from './styles';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { useIntl } from 'react-intl';
import moment from 'moment';

const useStyles = makeStyles(styles);

export default function ConsumptionAndDemandChart(props) {
  const intl = useIntl();
  const classes = useStyles();
  const { className, consumes, ...rest } = props;
  const generalReport = useSelector(
    state => state.chargeDashboard.generalReport.report
  );

  const [days, setDays] = useState([]);
  const [totals, setTotals] = useState({});

  const localeNav = useSelector(state => state.prefs.locale);

  useEffect(() => {
    const daysInMonth = moment().daysInMonth();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const daysArray = [];
    moment.locale(localeNav);
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment({ year: currentYear, month: currentMonth, day: i });
      daysArray.push({
        day: i,
        weekday: date.format('ddd')
      });
    }

    setDays(daysArray);
  }, [localeNav]);

  const labels = days.map(day => `${day.weekday.charAt(0).toUpperCase() + day.weekday.slice(1, 3)} ${day.day}`);

  const data = {
    labels: labels,
    datasets: [
      {
        label: `${intl.formatMessage({ id: 'app.charge.board.dailyAcumConsump.dataSet.base' })} kWh`,
        backgroundColor: '#bfe5bf',
        data: [],
        type: 'bar',
      },
      {
        label: `${intl.formatMessage({ id: 'app.charge.board.dailyAcumConsump.dataSet.interm' })} kWh`,
        backgroundColor: '#f2e5bf',
        data: [],
        type: 'bar',
      },
      {
        label: `${intl.formatMessage({ id: 'app.charge.board.dailyAcumConsump.dataSet.tip' })} kWh`,
        backgroundColor: '#f2bfbf',
        data: [],
        type: 'bar',
      },
      // {
      //   label: intl.formatMessage({ id: 'app.charge.board.dailyAcumConsump.dataSet.tendency' }),
      //   borderWidth: 2,
      //   borderColor: 'rgba(255,99,132,1)',
      //   borderDash: [10, 5],
      //   fill: false,
      //   data: [],
      //   type: 'line',
      // }
    ]
  };

  const arrayData = [];

  if (generalReport) {
    Object.values(generalReport).forEach((element, index) => {
      const object = {
        base: 0,
        intermediate: 0,
        punta: 0
      };

      const reducer = (accumulator, currentValue) => {
        if (currentValue.tariff === 'BASE')
          return {
            base: accumulator.base + parseFloat(currentValue.Kwh),
            intermediate: accumulator.intermediate,
            punta: accumulator.punta
          };

        if (currentValue.tariff === 'INTERMEDIO')
          return {
            base: accumulator.base,
            intermediate: accumulator.intermediate + parseFloat(currentValue.Kwh),
            punta: accumulator.punta
          };

        return {
          base: accumulator.base,
          intermediate: accumulator.intermediate,
          punta: accumulator.punta + parseFloat(currentValue.Kwh)
        };
      };

      arrayData.push({
        data: Object.values(element.reduce(reducer, object))
      });
    });
  }

  let trendData = [];
  const currentDay = moment().date();
  let totalDays = arrayData.length;

  arrayData.forEach((element, index) => {
    const baseValue = totals.base !== 0 ? parseFloat(element.data[0]) : 0;
    const intermediateValue = totals.intermediate !== 0 ? parseFloat(element.data[1]) : 0;
    const puntaValue = totals.punta !== 0 ? parseFloat(element.data[2]) : 0;

    const total = baseValue + intermediateValue + puntaValue;

    data.datasets[0].data.push(element.data[0].toFixed(3));
    data.datasets[1].data.push(element.data[1].toFixed(3));
    data.datasets[2].data.push(element.data[2].toFixed(3));
    if (currentDay === 1 || index < currentDay - 1) {

      if (total > 0) {
        trendData.push([/*index,*/ total]);
      }
    }
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      onClick: function (e, legendItem) {
        const index = legendItem.datasetIndex;
        const ci = this.chart;
        const meta = ci.getDatasetMeta(index);

        // Toggle the visibility
        meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

        // Update totals
        const newTotals = { base: 0, intermediate: 0, punta: 0 };
        ci.data.datasets.forEach((dataset, i) => {
          if (!ci.getDatasetMeta(i).hidden) {
            dataset.data.forEach((value, dataIndex) => {
              if (i === 0) newTotals.base += parseFloat(value);
              if (i === 1) newTotals.intermediate += parseFloat(value);
              if (i === 2) newTotals.punta += parseFloat(value);
            });
          }
        });

        setTotals(newTotals);

        // Re-render the chart
        ci.update();
      }
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: palette.border,
      backgroundColor: palette.common.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary,
      zIndex: 1200
    },
    layout: { padding: 0 },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [{
        stacked: true,
        position: 'right',
        ticks: {
          callback: function (value) {
            return value.toFixed(1);
          },
          beginAtZero: true,
          max: trendData ? Math.ceil((Math.max(...trendData) + 100) / 100) * 100 : 100,
        },
      }]
    },
    animation: {
      onComplete: function () {
        var chartInstance = this.chart;
        var ctx = chartInstance.ctx;
        ctx.textAlign = 'center';

        ctx.fillStyle = 'rgba(0, 0, 0, 1)';
        ctx.textBaseline = 'bottom';

        var metaBase = chartInstance.controller.getDatasetMeta(0);
        var metaIntermediate = chartInstance.controller.getDatasetMeta(1);
        var metaPunta = chartInstance.controller.getDatasetMeta(2);

        this.data.datasets[0].data.forEach(function (data, index) {
          var baseValues = !metaBase.hidden ? parseFloat(data) : 0;
          var intermediateValues = !metaIntermediate.hidden ? parseFloat(this.data.datasets[1].data[index]) : 0;
          var puntaValues = !metaPunta.hidden ? parseFloat(this.data.datasets[2].data[index]) : 0;
          var total = baseValues + intermediateValues + puntaValues;

          var posX;
          var posYBase = metaBase.hidden ? undefined : metaBase.data[index]._model.y;
          var posYIntermediate = metaIntermediate.hidden ? undefined : metaIntermediate.data[index]._model.y;
          var posYPunta = metaPunta.hidden ? undefined : metaPunta.data[index]._model.y;

          // Determinar posX basado en los datasets visibles
          if (!metaBase.hidden) {
            posX = metaBase.data[index]._model.x;
          } else if (!metaIntermediate.hidden) {
            posX = metaIntermediate.data[index]._model.x;
          } else if (!metaPunta.hidden) {
            posX = metaPunta.data[index]._model.x;
          }

          // Calcular la posición Y en función de los datasets visibles
          var posYArray = [posYBase, posYIntermediate, posYPunta].filter(y => y !== undefined);
          var posY = Math.min(...posYArray) - 5;

          ctx.fillText(total.toFixed(2), posX, posY);
        }, this);
      }
    },
  };


  // // Calcular la línea de tendencia
  // const result = regression.linear(trendData);
  // const slope = result.equation[0];
  // const intercept = result.equation[1];

  // const projectionDays = totalDays;
  // const minProjectionValue = Math.max(...trendData.map(d => d[1])) * 0.1;

  // for (let index = 0; index < projectionDays; index++) {
  //   if (index < totalDays) {
  //     const y = Math.max(minProjectionValue, slope * index + intercept);
  //     data.datasets[3].data.push(y.toFixed(3));
  //   } else {
  //     const y = Math.max(minProjectionValue, slope * index + intercept);
  //     data.labels.push(`${intl.formatMessage({ id: 'app.charge.board.dailyAcumConsump.dataSet.day' })} ${index + 1}`);
  //     data.datasets[0].data.push(0);
  //     data.datasets[1].data.push(0);
  //     data.datasets[2].data.push(0);
  //     data.datasets[3].data.push(y.toFixed(3));
  //   }
  // }

  const rootClassName = classNames(classes.root, className);

  return (
    <Portlet
      {...rest}
      className={rootClassName}
    >
      <PortletHeader noDivider>
        <PortletLabel title={intl.formatMessage({ id: 'app.charge.board.dailyAcumConsump.title' })} />
      </PortletHeader>
      <PortletContent>
        <div className={classes.chartWrapper}>
          {generalReport ? (<Bar
            data={data}
            options={options}
          />) : (<CircularProgress />)}

        </div>
      </PortletContent>
    </Portlet>
  );
}