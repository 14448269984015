/* eslint-disable react/jsx-sort-props */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

// Externals
import { useSelector, useDispatch } from 'react-redux';
import {
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import getToken from 'helpers/auth';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Highcharts, { time } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Button } from '@material-ui/core';
// Material helpers
import {
  makeStyles,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Table,
  TableBody,
  CircularProgress
} from '@material-ui/core';

// Component styles
import styles from './styles';
import { useIntl } from 'react-intl';
import theme from 'theme';
import moment from 'moment';
import MaterialTable from 'material-table';
const useStyles = makeStyles(styles);

export default function ChargeTable(props) {
  const intl = useIntl();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [dataDemand, setDataDemand] = React.useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showStartDatePop, setShowStartDatePop] = useState(false);
  const [showEndDatePop, setShowEndDatePop] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const dispatch = useDispatch();
  const { ids } = props;
  const generalReport = useSelector(
    state => state.chargeDashboard.generalReport.report
  );
  const [heatMap, setHeatMap] = useState({ fee: true, hm: false });
  const year = new Date().getFullYear();
  const [stats, setStats] = useState([]);
  const [timeLabel, setTimeLabel] = useState({ min: true, hour: false });
  const getCurrentMonth = () => {
    let month = new Date().getMonth() + 1;
    if (month < 10) {
      return '0' + month;
    } else {
      return month;
    }
  };

  const concentrator_id = useSelector(
    state => state.chargeDashboard.generalConsumes.concentrator_id
  );

  const charge = useSelector(
    state => state.chargeDashboard.generalConsumes.charge_info
  );

  const charge_id = charge ? charge.id : 0;

  const data = [];
  let obj;

  const calculateStats = data => {
    if (data.length === 0) return { min: 0, max: 0, avg: 0 };
    const values = data.map(item => item[1]);
    const min = Math.min(...values).toFixed(2);
    const max = Math.max(...values).toFixed(2);
    const avg = (
      values.reduce((sum, value) => sum + value, 0) / values.length
    ).toFixed(2);
    return { min, max, avg };
  };

  const getColorFont = obj => {
    if (obj.max_kw == 1) {
      return '#ad1f1f';
    } else if (obj.min_kw == 1) {
      return '#3ba400';
    } else {
      return '#12161B';
    }
  };

  const handleHM = event => {
    const { name, checked } = event.target;
    if (name === 'fee' && checked) {
      setHeatMap({
        fee: true,
        hm: false
      });
    } else if (name === 'hm' && checked) {
      setHeatMap({
        fee: false,
        hm: true
      });
    } else {
      setHeatMap(prevState => ({
        ...prevState,
        [name]: checked
      }));
    }
  };

  if (generalReport != undefined || generalReport != null) {
    Object.values(generalReport).forEach(element => {
      element.forEach(element2 => {
        obj = {
          hora: element2.Hora,
          medicion: element2.Kw,
          color: heatMap.fee ? element2.color : element2.color_hm_kw,
          alerta_demanda: element2.alerta_demanda,
          max_day: element2.max_day,
          max_month: element2.max_month,
          min_day: element2.min_day,
          min_month: element2.min_month,
          font_color: getColorFont(element2),
          font_weight:
            element2.alerta_demanda == 1 ||
            element2.max_kw == 1 ||
            element2.min_kw == 1
              ? 'bold'
              : 'normal'
        };

        data.push(obj);
      });
    });
  }

  const res = data.reduce((acc, curr) => {
    if (!acc[curr.hora]) acc[curr.hora] = []; //If this type wasn't previously stored
    acc[curr.hora].push(curr);
    return acc;
  }, {});

  const handleClickOpen = () => {
    setOpen(true);
    filter5MinRecords(startDate, endDate);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeLabel({ min: true, hour: false });
  };

  const filter5MinRecords = (fechaIni, fechaFin) => {
    let dayDifs = getDateDiffInDays(fechaIni, fechaFin);
    if (dayDifs > 31) {
      setErrorMessage(
        '[' +
          dayDifs +
          ' ' +
          intl.formatMessage({
            id: 'app.deepview.demand.datesRangeError.rangeTooLong'
          })
      );
      return;
    } else {
      if (dayDifs < 0) {
        setErrorMessage(
          intl.formatMessage({
            id: 'app.deepview.demand.datesRangeError.wrongIniEnd'
          })
        );
        return;
      }

      setErrorMessage(null);
    }

    let txtIniDate = getDateAsString(fechaIni);
    let txtEndDate = getDateAsString(fechaFin);
    txtIniDate = txtIniDate + '00';
    txtEndDate = txtEndDate + '23';
    dispatch(get5MinRecords(txtIniDate, txtEndDate));
  };

  const getDateDiffInDays = (startDate, endDate) => {
    const diffTime = endDate - startDate;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1;
  };

  const handleSelectStartDate = iniDate => {
    setStartDate(iniDate);
    setShowStartDatePop(false);
    setDataDemand([]);
    setErrorMessage(null);
    filter5MinRecords(iniDate, endDate);
  };

  const handleSelectEndDate = finDate => {
    setEndDate(finDate);
    setShowEndDatePop(false);
    filter5MinRecords(startDate, finDate);
  };

  const getDateAsString = date => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    let strMonth = month.toString();
    if (month < 10) {
      strMonth = '0' + strMonth;
    }
    let strDay = day.toString();
    if (day < 10) {
      strDay = '0' + strDay;
    }

    let strDate = year + strMonth + strDay;
    return strDate;
  };

  const get5MinRecords = (startDate, endDate) => {
    return async dispatch => {
      axios
        .post(
          'https://api.metimur.mx/api/charge/5minutalpower',
          {
            concentrator_id: concentrator_id,
            charge_id: charge_id,
            start_date: /*'2023050100'*/ startDate,
            end_date: /* '2023051023'*/ endDate
          },
          {
            headers: { Authorization: 'Bearer ' + getToken() }
          }
        )
        .then(response => {
          set5MinDates(response.data.data);
        })
        .catch(function(error) {
          console.error(error);
        });
    };
  };

  const set5MinDates = data => {
    let idx = 0;
    let arr_days = [];
    let arrData = [];
    if (timeLabel.hour) {
      const hourlyData = {};
      for (idx = 0; idx < data.length; idx++) {
        const dateStr = data[idx].date.toString();
        const year = parseInt(dateStr.substring(0, 4));
        const month = parseInt(dateStr.substring(4, 6)) - 1;
        const day = parseInt(dateStr.substring(6, 8));
        const hour = parseInt(dateStr.substring(8));
        const date = new Date(year, month, day, hour);
        const dateKey = date.getTime();

        if (!hourlyData[dateKey]) {
          hourlyData[dateKey] = {
            date: date,
            maxValue: -Infinity
            // total: 0,
            // count: 0,
          };
        }

        arr_days = data[idx].data;
        for (let jj = 0; jj < arr_days.length; jj++) {
          const dayData = arr_days[jj];
          hourlyData[dateKey].maxValue = Math.max(
            hourlyData[dateKey].maxValue,
            dayData.value
          );
          // hourlyData[dateKey].total += dayData.value;
          // hourlyData[dateKey].count++;
        }
      }

      for (const dateKey in hourlyData) {
        const hourlyDataPoint = hourlyData[dateKey];
        // const avgValue = hourlyDataPoint.total / hourlyDataPoint.count;
        // arrData.push([hourlyDataPoint.date.getTime(), avgValue]);
        arrData.push([
          hourlyDataPoint.date.getTime(),
          hourlyDataPoint.maxValue
        ]);
      }
    } else {
      for (idx = 0; idx < data.length; idx++) {
        arr_days = data[idx].data;
        const str = data[idx].date.toString(); // yyyy-mm-dd-hh format

        const year = parseInt(str.substring(0, 4));
        const month = parseInt(str.substring(4, 6)) - 1;
        const day = parseInt(str.substring(6, 8));
        const hour = parseInt(str.substring(8));

        for (let jj = 0; jj < arr_days.length; jj++) {
          const dayData = arr_days[jj];
          const minute = dayData.min;
          const date = new Date(year, month, day, hour, minute);
          const milisecs = date.getTime();
          const valData = [milisecs, dayData.value];
          arrData.push(valData);
        }
      }
    }

    setDataDemand(arrData);
    setStats(calculateStats(arrData));
  };

  useEffect(() => {
    filter5MinRecords(startDate, endDate);
  }, [timeLabel]);

  const handleTime = event => {
    const { name, checked } = event.target;
    if (name === 'min' && checked) {
      setTimeLabel({
        min: true,
        hour: false
      });
    } else if (name === 'hour' && checked) {
      setTimeLabel({
        min: false,
        hour: true
      });
    }
  };

  const options = {
    lang: {
      months: [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ],
      weekdays: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado'
      ],
      shortMonths: [
        'Ene',
        'Feb',
        'Mar',
        'Abr',
        'May',
        'Jun',
        'Jul',
        'Ago',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      shortWeekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    },
    turboThreshold: 0,
    time: {
      useUTC: false,
      timezone: 'America/Mexico_City'
    },
    credits: {
      enabled: false
    },
    title: {
      text: intl.formatMessage({ id: 'app.deepview.demand.chart.title' })
    },
    xAxis: {
      type: 'datetime',
      crosshair: true,

      dateTimeLabelFormats: {
        millisecond: '%H:%M:%S.%L',
        second: '%H:%M:%S',
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%a %e. %b',
        week: '%e. %b',
        month: '%b \'%y',
        year: '%Y'
      }
    },
    yAxis: [
      {
        title: {
          text: 'kW'
        },
        labels: {
          format: '{value}kW'
        }
      }
    ],
    rangeSelector: {
      enabled: true,
      selected: 4
    },
    tooltip: {
      positioner: function(labelWidth, labelHeight, point) {
        const chart = this.chart;
        // const position = point.plotY + chart.plotTop - 100; // Ajusta según tus necesidades
        // return { x: point.plotX + chart.plotLeft, y: position };
        let x = point.plotX + chart.plotLeft;
        let y = point.plotY + chart.plotTop - 100;

        if (x + labelWidth > chart.chartWidth) {
          x = chart.chartWidth - labelWidth - 10;
        }
        if (y + labelHeight > chart.chartHeight) {
          y = chart.chartHeight - labelHeight - 10;
        }
        return { x: x, y: y };
      },

      formatter: function() {
        const date = new Date(this.x);
        const options = {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          weekday: 'long'
        };

        const formattedDate = date
          .toLocaleString(intl.formatMessage({ id: 'app.locale' }), options)
          .replace(',', ''); // replace the comma with an empty string

        return (
          '<b>' +
          this.series.name +
          '</b><br/>' +
          formattedDate +
          //new Date(this.x).toString()
          '<br/>' +
          Highcharts.numberFormat(this.y, 2) +
          ' kW'
        );
      }
    },
    series: [
      {
        name: intl.formatMessage({
          id: 'app.deepview.demand.chart.demandSerie.label'
        }),
        turboThreshold: 0,
        data: dataDemand /* dataConsume.map((point) => ({
      x: point[0],
      y: point[1],
      color: point[1] > 0.5 ? "red" : "green",
    }))  */,
        id: 'dataseries',

        tooltip: {
          valueDecimals: 4
        }
      }
    ],
    legend: {
      itemStyle: {
        fontSize: '16px',
        fontWeight: 'bold'
      }
    }
  };

  Highcharts.setOptions({
    lang: {
      months: [
        intl.formatMessage({ id: 'app.months.jan' }),
        intl.formatMessage({ id: 'app.months.feb' }),
        intl.formatMessage({ id: 'app.months.mar' }),
        intl.formatMessage({ id: 'app.months.apr' }),
        intl.formatMessage({ id: 'app.months.may' }),
        intl.formatMessage({ id: 'app.months.jun' }),
        intl.formatMessage({ id: 'app.months.jul' }),
        intl.formatMessage({ id: 'app.months.ago' }),
        intl.formatMessage({ id: 'app.months.sep' }),
        intl.formatMessage({ id: 'app.months.oct' }),
        intl.formatMessage({ id: 'app.months.nov' }),
        intl.formatMessage({ id: 'app.months.dec' })
      ],
      weekdays: [
        intl.formatMessage({ id: 'app.daysOfWeek.sun' }),
        intl.formatMessage({ id: 'app.daysOfWeek.mon' }),
        intl.formatMessage({ id: 'app.daysOfWeek.tue' }),
        intl.formatMessage({ id: 'app.daysOfWeek.wed' }),
        intl.formatMessage({ id: 'app.daysOfWeek.thu' }),
        intl.formatMessage({ id: 'app.daysOfWeek.fri' }),
        intl.formatMessage({ id: 'app.daysOfWeek.sat' })
      ],
      shortMonths: [
        intl.formatMessage({ id: 'app.monthsShort.jan' }),
        intl.formatMessage({ id: 'app.monthsShort.feb' }),
        intl.formatMessage({ id: 'app.monthsShort.mar' }),
        intl.formatMessage({ id: 'app.monthsShort.apr' }),
        intl.formatMessage({ id: 'app.monthsShort.may' }),
        intl.formatMessage({ id: 'app.monthsShort.jun' }),
        intl.formatMessage({ id: 'app.monthsShort.jul' }),
        intl.formatMessage({ id: 'app.monthsShort.aug' }),
        intl.formatMessage({ id: 'app.monthsShort.sep' }),
        intl.formatMessage({ id: 'app.monthsShort.oct' }),
        intl.formatMessage({ id: 'app.monthsShort.nov' }),
        intl.formatMessage({ id: 'app.monthsShort.dec' })
      ],
      shortWeekdays: [
        intl.formatMessage({ id: 'app.daysOfWeekShort.sun' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.mon' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.tue' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.wed' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.thu' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.fri' }),
        intl.formatMessage({ id: 'app.daysOfWeekShort.sat' })
      ]
    }
  });

  const columns = [
    {
      title: intl.formatMessage({ id: 'app.metersCards.card.voltageMin' }),
      field: 'min',
      cellStyle: {
        backgroundColor: '#8CBA42',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '.95em',
        width: '30%'
      }
    },
    {
      title: intl.formatMessage({ id: 'app.metersCards.card.voltageMax' }),
      field: 'max',
      cellStyle: {
        backgroundColor: '#D64539',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '.95em',
        width: '30%'
      }
    },
    {
      title: intl.formatMessage({ id: 'app.metersCards.card.voltageAve' }),
      field: 'avg',
      cellStyle: {
        backgroundColor: '#EAAB36',
        color: '#FFF',
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '.95em',
        width: '30%'
      }
    }
  ];

  const chipIndicator = () => {
    if (heatMap.fee) {
      return (
        <Grid
          item
          spacing={2}
          className={classes.topBar}
        >
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipBase'
            })}
            style={{
              backgroundColor: '#bfe5bf',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipIntermed'
            })}
            style={{
              backgroundColor: '#f2e5bf',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipTip'
            })}
            style={{
              backgroundColor: '#f2bfbf',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
        </Grid>
      );
    } else {
      return (
        <Grid
          item
          spacing={2}
          className={classes.topBar}
        >
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipBaja'
            })}
            style={{
              backgroundColor: '#78E378',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipBajaMed'
            })}
            style={{
              backgroundColor: '#A4E378',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipMedia'
            })}
            style={{
              backgroundColor: '#E3E378',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipMediaAlt'
            })}
            style={{
              backgroundColor: '#E3A478',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
          <Chip
            label={intl.formatMessage({
              id: 'app.charge.board.dailyRepDemand.chipAlta'
            })}
            style={{
              backgroundColor: '#E37878',
              fontSize: '.95rem',
              fontWeight: 500
            }}
          />
        </Grid>
      );
    }
  };

  const [days, setDays] = useState([]);
  const localeNav = useSelector(state => state.prefs.locale);

  useEffect(() => {
    const daysInMonth = moment().daysInMonth();
    const currentMonth = moment().month();
    const currentYear = moment().year();
    const daysArray = [];
    moment.locale(localeNav);
    for (let i = 1; i <= daysInMonth; i++) {
      const date = moment({ year: currentYear, month: currentMonth, day: i });
      daysArray.push({
        day: i,
        weekday: date.format('ddd')
      });
    }

    setDays(daysArray);
  }, [localeNav]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid
          container
          lg={12}
          md={12}
          xl={12}
          xs={12}
          style={{
            width: '100%',
            justifyContent: 'space-between',
            marginBottom: theme.spacing.unit,
            alignItems: 'center'
          }}
        >
          <Chip
            label={intl.formatMessage({
              id: 'app.chargeSolar.board.dailyRepDemand.title'
            })}
            className={classes.title}
          />
          <Grid item>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={heatMap.fee}
                    onChange={handleHM}
                    name="fee"
                  />
                }
                label={intl.formatMessage({ id: 'app.chargesCards.card.hourlyRate' })}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={heatMap.hm}
                    onChange={handleHM}
                    name="hm"
                  />
                }
                label={intl.formatMessage({ id: 'app.barmenu.headMap' })}
              />
            </FormGroup>
          </Grid>
          {chipIndicator()}
          {/* <Button
            className={classes.roundButton}
            color="secondary"
            onClick={handleClickOpen}
            size="small"           
            variant="contained"
          >{intl.formatMessage({id: 'app.deepview.demand.btnOpen'})}</Button>   */}
        </Grid>
        <PerfectScrollbar>
          <Table
            className={classes.table}
            size="small"
            style={{ width: '100%' }}
          >
            <TableHead>
              {generalReport != undefined || generalReport != null ? (
                <TableRow>
                  <TableCell className={classes.positionLeft}>
                    {intl.formatMessage({
                      id: 'app.charge.board.dailyRepDemand.hourLab'
                    })}
                  </TableCell>
                  {days.map((day, index) => (
                    <TableCell key={index}>
                      {day.day}
                      <br />
                      {day.weekday.charAt(0).toUpperCase() +
                        day.weekday.slice(1, 3)}
                    </TableCell>
                  ))}
                  {/* {Object.values(generalReport).map((columns,index) => (
                  <TableCell >
                    {index + 1}
                  </TableCell>
                ))} */}
                </TableRow>
              ) : null}
            </TableHead>

            {generalReport ? (
              <TableBody style={{ padding: '0 !important' }}>
                {Object.values(res)
                  .sort((a, b) => a[0].hora - b[0].hora)
                  .map(row => (
                    <TableRow style={{ height: '0px' }}>
                      <TableCell
                        component="th"
                        scope="row"
                        className={classes.positionLeft}
                      >
                        {row[0].hora >= 10 ? row[0].hora : row[0].hora}:00
                      </TableCell>
                      {row.map(row => (
                        <TableCell
                          component="th"
                          scope="row"
                          style={{
                            root: { padding: '10px 13px' },
                            backgroundColor: row.color,
                            fontWeight: row.font_weight,
                            color: row.font_color,
                            border: '1px solid #7e7e7e'
                          }}
                        >
                          {row.medicion}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            ) : (
              <CircularProgress />
            )}
          </Table>
        </PerfectScrollbar>
      </Paper>

      {/* <Dialog
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={open}
      >
        <DialogTitle >
          <Typography
            className={classes.title}
            variant="h3"
          >{intl.formatMessage({id: 'app.deepview.demand.title'})}</Typography>
        </DialogTitle>
           
        <DialogContent>
          <DialogContentText>
            <Typography
              className={classes.title}
              style={{ color: 'red' }}
              variant="h5"
            >{errorMessage}</Typography> 
            <Typography  variant="h5"  > {intl.formatMessage({id: 'app.deepview.demand.datesMsg'})} </Typography>
            <Grid
              style={{paddingTop: '1rem', display: 'flex', justifyContent: 'space-between'}}
            >
              <Grid item>
                <MuiPickersUtilsProvider   utils={DateFnsUtils}   >
                  <KeyboardDatePicker    
                    format="dd/MM/yyyy"
                    InputProps={{onFocus: () => { setShowStartDatePop(true);}}}  
                    inputVariant="outlined"
                    KeyboardButtonProps={{onFocus: e => {setShowStartDatePop(true); }}}
                    onChange={date =>  handleSelectStartDate(date)}
                    open={showStartDatePop}
                    PopoverProps={{
                      disableRestoreFocus: true,
                      onClose: () => {
                        setShowStartDatePop(false);
                      }
                    }}
                    style={{ marginBottom : '10px' , marginRight: '10px' }}
                    value={startDate}
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider   utils={DateFnsUtils}   >
                  <KeyboardDatePicker 
                    format="dd/MM/yyyy"
                    InputProps={{
                      onFocus: () => {
                        setShowEndDatePop(true);
                      }
                    }}  
                    inputVariant="outlined"
                    KeyboardButtonProps={{
                      onFocus: e => {
                        setShowEndDatePop(true);
                      }
                    }}
                    onChange={date =>{ setEndDate(date);handleSelectEndDate(date)}}
                    open={showEndDatePop}
                    PopoverProps={{
                      disableRestoreFocus: true,
                      onClose: () => {
                        setShowEndDatePop(false);
                      }
                    }}
                    style={{ marginBottom : '10px' , marginRight: '10px' }}
                    value={endDate}
                    variant="inline"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={timeLabel.min}
                        onChange={handleTime}
                        name="min"
                      />
                    }
                    label={intl.formatMessage({ id: 'app.charge.board.min'})}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="secondary"
                        checked={timeLabel.hour}
                        onChange={handleTime}
                        name="hour"
                      />
                    }
                    label={intl.formatMessage({ id: 'app.charge.board.hour' })}
                  />
                </FormGroup>
              </Grid>
            </Grid>
                        
          </DialogContentText>
          <Paper    >             
            <HighchartsReact
              highcharts={Highcharts}
              options={options}                
            />
          </Paper>
          <Grid
            container
            spacing={3}
            style={{marginTop: '1rem', display: 'flex', justifyContent: 'center'}}
          >
            <Grid
              item
              lg={4}
              md={6}
              xs={12}
            >
              <Typography
                style={{marginBottom: '1rem', textAlign: 'center'}}
                variant="h4"
              >{intl.formatMessage({id: 'app.deepview.demand.chart.demandSerie.label'})} (kW)</Typography>
              <MaterialTable
                columns={columns}
                data={[stats]}
                options={{
                  filtering: false,
                  search: false,
                  sorting: false,
                  toolbar: false,
                  paging: false,
                  rowStyle: {
                    fontSize: 15,
                  },
                  headerStyle: {
                    textAlign: 'center',
                    padding: '0',
                    fontSize: '0.8em',
                    fontWeight: '900',
                    minWidth: '25%',
                  },
                }}
              />
            </Grid>
          </Grid>                    
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={handleClose}
            size="small"           
            style={{ marginBottom : '10px' , marginRight: '15px' }}
            variant="contained"
          >{intl.formatMessage({id: 'app.deepview.demand.btnCancel'})}</Button>         
        </DialogActions>
      </Dialog> */}
    </div>
  );
}
